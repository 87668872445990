<template>
  <div class="table-container">

    <Loading 
      v-if="loading"
      pixeles="150px"
    />
    <template v-if="!loading">  
      <table class="table table-custom table-borderless table-custom-responsive mb-0">
        <thead>
          <tr>
            <th scope="col">N°</th>
            <th scope="col" class="text-center">Nombre</th>
            <th scope="col" class="text-center">Código</th>
            <th scope="col" class="text-center">Canal</th>
            <th scope="col" class="text-center">Estado</th>
            <th scope="col" class="text-center">Acciones</th>
          </tr>
        </thead>
        <tbody v-show="pantallas.length > 0">
          <tr
            v-for="(pantalla, i) in pantallas"
            :key="i"
          >
            <td class="th th-numero">{{ i + 1 }}</td>
            <!-- <td class="th th-numero">{{ pantalla.id_pantalla }}</td> -->
            <td class="th th-nombre">{{ pantalla.nombre_pantalla }}</td>
            <td class="th th-codigo text-center">{{ pantalla.codigo }}</td>
            <td class="th th-canal text-center">
              <span
                v-show="pantalla.canal.length >= 1"
                v-for="(c, i) in pantalla.canal"
                :key="i"
              >
                {{ c.nombre_canal }}
            </span>
              <span v-show="pantalla.canal.length === 0">-</span>
            </td>
            <td class="th th-estado text-center">
              <span 
                v-if="pantalla.estado.nombre_estado === 'Online'"
                class="badge badge-custom d-block activo">
                {{ pantalla.estado.nombre_estado }}
              </span>
              <span 
                v-if="pantalla.estado.nombre_estado === 'Offline'"
                class="badge badge-custom d-block inactivo">
                {{ pantalla.estado.nombre_estado }}
              </span>
            </td>
            <td class="th th-accion text-center">
  
              <div class="dropdown dropdown-custom d-flex justify-content-center">
                  <button 
                    :aria-label="'Opciones de la patalla ' + pantalla.id_pantalla"
                    @click="openOption(pantalla.id_pantalla)"
                    class="dropdown-toggle dropdown-custom-action dropdown-custom-arrow-hide" 
                    type="button"
                    data-bs-toggle="dropdown">
                    <font-awesome-icon icon="ellipsis-vertical"/>
                  </button>
                  <ul 
                    class="dropdown-menu"
                    v-click-outside="outOption">
                    <li>
                      <a 
                        @click="editScreen(pantalla.id_pantalla)"
                        class="dropdown-item" 
                        href="javascript:">
                        <font-awesome-icon class="pe-2 color-main" icon="pen"/>
                        Editar
                      </a>
                    </li>
                    <li>
                      <a 
                        @click="deleteScreen(pantalla.id_pantalla)"
                        class="dropdown-item" 
                        href="javascript:">
                        <font-awesome-icon class="pe-2 color-main" icon="trash-alt"/>
                        Eliminar
                      </a>
                    </li>
                  </ul>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </template>

    <SinResultados v-if="!loading && pantallas.length === 0"/>
  </div>
</template>

<script>
  //FF
  import Loading from "@/components/Loading.vue";
  import SinResultados from "@/components/SinResultados.vue";

  export default {
    components: { 
      //FF
      Loading,
      SinResultados
    },
    props: {
      pantallas: {
        type: Array,
      },
      loading: {
        type: Boolean,
        required: false
      }
    },
    
    data() {
      return {
        option: "-1",
        id_empresa: this.$ls.get("user").empresa[0].id_empresa,
        id_usuario: this.$ls.get("user").id_usuario,
      };
    },

    methods: {
      openOption(i) {
        if (this.option == i) {
          this.option = -1;
        } else {
          setTimeout(() => {
            this.option = i;
          }, 10);
        }
      },
      outOption() {
        if (this.option != -1) {
          this.option = -1;
        }
      },
      deleteScreen(id_pantalla) {
        this.$emit("deleteScreen", id_pantalla);
      },
      editScreen(id_) {
        this.$router.push({
          name: "CanalCorporativo-edit-pantallas",
          params: {
            id: id_,
          },
        });
      },
    },
  };
</script>
