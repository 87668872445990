<template>
  <section id="pantallas">
    <div class="row align-items-center my-3">
      <div class="col-12 col-lg-9">
        <h3 class="section-head-title ps-0 mb-3 mb-lg-0">
          Aquí se muestran todas las pantallas que has creado
        </h3>
      </div>
      <div class="col-12 col-lg-3 d-flex justify-content-end">
        <button
          @click="
            areThereScreensAvailable ? addPantalla() : modalIncreaseScreenPlan()
          "
          class="btn btn-custom btn-custom-color-blue btn-shadow border-round-50"
          :class="{ 'disabled-working': !areThereScreensAvailable }"
        >
          <font-awesome-icon icon="plus" class="pe-2" />
          Añadir pantalla
        </button>
      </div>
    </div>

    <div class="row">
      <div class="col-12">
        <div class="alert alert-info">
          Plan contratado
          {{ CantidadPantallasAdmin.pantallas_contrato }} pantallas /
          {{ CantidadPantallasAdmin.registradas }} Pantallas registradas
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-12">
        <PantallasTable
          :pantallas="GetPantallaList"
          @deleteScreen="deleteScreen"
        />
      </div>
    </div>

    <Question
      v-if="question_modal"
      :msg="question_modal_msg"
      :hideCancel="false"
      acceptText="Confirmar"
      @cancel="cancelDelete"
      @accept="acceptDelete"
    />
    <Question
      v-if="question_modal_increment"
      :msg="question_modal_increment_msg"
      :hideCancel="false"
      acceptText="Contáctame"
      @cancel="cancelIncrease"
      @accept="increaseScreenPlan"
    />
  </section>
</template>

<script>
import { mapState, mapActions, mapGetters } from "vuex";
import PantallasTable from "./PantallasTable";
import Question from "../Modales/Question.vue";
// areThereScreensAvailable ? "Añadir pantalla" : "Obtener pantallas"
export default {
  components: {
    PantallasTable,
    Question,
  },
  data() {
    return {
      question_modal: false,
      question_modal_msg: "",
      question_modal_increment: false,
      question_modal_increment_msg: "",

      deletingIdPantalla: 0,
      id_empresa: this.$ls.get("user").empresa[0].id_empresa,
      pagination: {
        actual_page: 1,
        page_count: 0,
        page_range: 3,
        click_handler: this.changePage,
        prev_text:
          '<div class="btn-prevnext"><i class="fas fa-chevron-left"></i></div>',
        next_text:
          '<div class="btn-prevnext"><i class="fas fa-chevron-right"></i></div>',
        container_class: "users-list__pagination-container",
        page_class: "pagination-item",

        //FF
        loading: true,
      },
    };
  },

  computed: {
    ...mapState("canalCorporativoModule", [
      "GetPantallaList",
      "CantidadPantallasAdmin",
    ]),
    ...mapGetters("canalCorporativoModule", ["areThereScreensAvailable"]),
  },

  async created() {
    await this.getPantallaListFunction();
    await this.getCantidadPantallasFunction();
  },

  methods: {
    ...mapActions("canalCorporativoModule", [
      "GetPantallaListAction",
      "GetCantidadPantallasAction",
      "DeleteScreenAction",
      "sendMailNewScreen",
    ]),

    addPantalla() {
      this.$router.push({ name: "CanalCorporativo-add-pantallas" });
    },
    modalIncreaseScreenPlan() {
      this.question_modal_increment = true;
      this.question_modal_increment_msg =
        "Has alcanzado el límite de pantallas en tu plan. ¿Te gustaría actualizar para agregar más?";
    },
    cancelIncrease() {
      this.question_modal_increment = false;
      this.question_modal_increment_msg = "";
    },
    async increaseScreenPlan() {
      const resp = await this.sendMailNewScreen();
      if (resp == null) {
        this.$toast.open({
          message:
            "Hemos presentado un problema al momento de obtener tus datos, intenta más tarde.",
          type: "error",
          duration: 6000,
          position: "top-right",
        });
      }
      if (resp && resp.envio_correo) {
        this.$toast.open({
          message: resp.mensaje_modal,
          type: "success",
          duration: 6000,
          position: "top-right",
        });
        this.cancelIncrease();
        return;
      }
      this.$toast.open({
        message: resp.type_error,
        type: "error",
        duration: 6000,
        position: "top-right",
      });
      this.cancelIncrease();
    },

    async getPantallaListFunction() {
      this.loading = true;
      const result = await this.GetPantallaListAction();
      if (result == null) {
        this.$toast.open({
          message:
            "Problemas al cargar información, por favor contactar a soporte",
          type: "error",
          duration: 0,
          position: "top-right",
        });
        this.loading = false;
      } else {
        this.loading = false;
      }
    },
    async getCantidadPantallasFunction() {
      const result = await this.GetCantidadPantallasAction();
      if (result == null) {
        this.$toast.open({
          message:
            "Problemas al cargar información, por favor contactar a soporte",
          type: "error",
          duration: 0,
          position: "top-right",
        });
      }
    },
    async acceptDelete() {
      this.loading = true;
      let resDelete = await this.DeleteScreenAction({
        id_pantalla: this.deletingIdPantalla,
      });
      if (resDelete != null) {
        if (resDelete.id_pantalla != null) {
          this.question_modal = false;
          this.$toast.open({
            message: "Tu pantalla ha sido eliminada correctamente",
            type: "success",
            duration: 6000,
            position: "top-right",
          });
        } else {
          this.$toast.open({
            message:
              "No se pudo eliminar la pantalla, por favor intentar más tarde",
            type: "error",
            duration: 6000,
            position: "top-right",
          });
        }
      } else {
        this.$toast.open({
          message:
            "Problemas al cargar información, por favor contactar a soporte",
          type: "error",
          duration: 0,
          position: "top-right",
        });
      }
      this.loading = false;
    },

    cancelDelete() {
      this.question_modal = false;
    },
    deleteScreen(data) {
      this.deletingIdPantalla = data;
      this.question_modal = true;
      this.question_modal_msg =
        "¿Seguro que quieres eliminar esta pantalla?, tendrás que vincularla nuevamente.";
    },
  },
};
</script>
